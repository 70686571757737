<template>
  <authentication-form
    :title="$t('auth.login.title')"
    :submit-text="$t('login')"
    class="login"
    @submit="submitForm"
  >
    <van-field
      v-model="email"
      reference="email"
      name="email"
      type="email"
      :label="$t('email')"
      tabindex="1"
      :rules="[
        { required: true, message: $t('auth.validation.email.required') },
        { validator: emailValidator, message: $t('auth.validation.email.invalid') },
      ]"
      :error="!!emailError || !!loginError || email.validateFailed"
      :error-message="emailError || loginError"
    />
    <van-field
      v-model="password"
      reference="password"
      name="password"
      type="password"
      :label="$t('password')"
      placeholder="****"
      :rules="[{ required: true, message: $t('auth.validation.password.required') }]"
      :error="!!passwordError || password.validateFailed"
      :error-message="passwordError"
    />
    <router-link
      class="login__password-reset"
      :to="{ name: 'reset' }"
    >
      {{ $t('auth.login.passwordForgotten') }}
    </router-link>
    <template #footer>
      <van-button
        type="hollow-white"
        size="large"
        :icon="$icon('google-white')"
        :text="$t('auth.login.loginWith', { provider: 'Google' })"
        class="login__google van-button--with-icon"
        @click="toggleTermsPopup"
      />
      <van-button
        :to="{ name: 'register' }"
        type="hollow-white"
        :text="$t('register')"
      />
    </template>
    <van-popup
      v-model="showTermsPopup"
      position="bottom"
    >
      <div class="terms-form">
        <van-checkbox
          v-model="termsAccepted"
          click="acceptTerms"
        >
          <i18n
            path="acceptTerms"
            tag="label"
          >
            <router-link :to="{ name: 'privacy' }">{{ $t('privacyLabel') }}</router-link>
            <router-link :to="{ name: 'terms' }">{{ $t('termsLabel') }}</router-link>
          </i18n>
        </van-checkbox>
        <van-button
          type="primary"
          :disabled="!termsAccepted"
          block
          round
          @click="loginWithGoogle"
        >
          {{ $t('continue') }}
        </van-button>
      </div>
    </van-popup>
  </authentication-form>
</template>

<script>
import { Notify } from 'vant';
import firebase from 'firebase/app';
import { auth, analytics, db } from '@/firebase';
import UserProfile from '@/models/user-profile';
import Food from '@/models/food';
import store from '@/store';
import { emailValidator } from '@/validators';
import AuthenticationForm from '@/components/AuthenticationForm/AuthenticationForm.vue';

export default {
  components: {
    AuthenticationForm,
  },
  data() {
    return {
      email: '',
      password: '',
      emailError: '',
      passwordError: '',
      loginError: '',
      showTermsPopup: false,
      termsAccepted: false,
    };
  },
  methods: {
    emailValidator(value) {
      return emailValidator(value);
    },
    loginWithGoogle() {
      const provider = new firebase.auth.GoogleAuthProvider();
      provider.addScope('profile');
      provider.addScope('email');
      auth.signInWithPopup(provider)
        .then((result) => {
          this.trackLoginEvent(
            result.additionalUserInfo.providerId,
            result.additionalUserInfo.isNewUser,
          );
        });
    },
    trackLoginEvent(method, isNew = false) {
      analytics.logEvent(isNew ? 'sign_up' : 'login', {
        method,
        event_category: 'engagement',
        event_label: this.$i18n.t('auth.login.loginWith', { provider: 'Google' }),
      });
    },
    submitForm(values) {
      const that = this;
      auth.signInWithEmailAndPassword(values.email, values.password)
        .then(async (result) => {
          this.trackLoginEvent(
            result.additionalUserInfo.providerId,
            result.additionalUserInfo.isNewUser,
          );
          // Store the user's profile in the vuex state.
          const userProfile = await new UserProfile(result.user.uid).get();
          store.commit('user/setDiagnosisName', userProfile.diagnosisName);
          store.commit('user/setNutritionLimits', userProfile.nutritionLimits);
        })
        .catch((error) => {
          if (error.code === 'auth/network-request-failed') {
            Notify({
              type: 'danger',
              message: this.$i18n.t('auth.network.offline'),
              className: 'auth-notification',
            });
          } else if (error.code === 'auth/user-not-found') {
            that.loginError = this.$i18n.t('auth.login.error.userNotFound');
          } else if (error.code === 'auth/wrong-password') {
            that.passwordError = this.$i18n.t('auth.login.error.wrongPassword');
          } else {
            // @todo Production app crash/error reporting.
            console.error(error);
          }
        })
        .finally(() => {
          const foods = db.collection('foods')
            .where('createdBy', 'in', [1, auth.currentUser.uid]);
          foods.get();
          Food.getRecentItems();

          store.commit('user/setLastSync', new Date().toISOString());
        });
    },
    acceptTerms() {
      this.termsAccepted = !this.termsAccepted;
    },
    toggleTermsPopup() {
      this.showTermsPopup = !this.showTermsPopup;
    },
  },
};
</script>

<style lang="scss" scoped>
@use '~@/styles/config' as config;

.login__or {
  margin: config.$spacing-lg 0;
  text-align: center;
}

.login__password-reset {
  display: block;
  margin: 1em 0 1.125em;
  color: #fff;
  font-weight: 600;
  line-height: 1.125;
  text-align: center;
  text-decoration: underline;
}
.terms-form {
  padding: 28px 16px;

  .van-checkbox {
    align-items: flex-start;
  }

  ::v-deep .van-checkbox__icon {
    margin-top: 0.1em;
  }

  .van-checkbox__label label {
    margin: 0 0 20px;
    padding-left: 12px;
    display: block;
    line-height: 1.8em;
    color: config.$color-darker;
  }
}
</style>
